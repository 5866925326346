/* eslint complexity: 0 */
var site = site || {};
var generic = generic || {};
var Mustache = Mustache || {};
var page_data = page_data || {};

site.custom_kits = site.custom_kits || {};
site.custom_kits.tray = site.custom_kits.tray || {};
site.custom_kits_product_tray = site.custom_kits_product_tray || {};

(function ($) {
  Drupal.behaviors.custom_kitsProductTray = {
    attach: function (context, settings) {
      this.getTrayData();
      this.getNodes();
      this.openTrayEvents();
      this.loadTemplates();
      this.setEvents();
      $(window).on(
        'scroll',
        function () {
          this.setFixedTray($(window).scrollTop());
        }.bind(this)
      );
    },

    data: {},
    collection: {},
    nodes: {},
    mustache: {},

    scrollTop: '',

    defaults: {
      disableClass: 'disable',
      maxThumbsPerRow: 6,
      maxTotalTumbs: 8,
      open: {
        duration: 1000,
        onComplete: function () {}
      },
      close: {
        duration: 1000,
        onComplete: function () {}
      }
    },

    setEvents: function () {
      $(document).on('custom_kits:addToGift:success', $.proxy(this.onAddToGiftSuccess, this));
      $(document).on('custom_kits:removeFromGift:success', $.proxy(this.onRemoveFromGiftSuccess, this));
      $(document).on('custom_kits:removeFromGift:failure', $.proxy(this.onRemoveFromGiftFailure, this));
      $(document).on('custom_kits:tray:close', $.proxy(this.closeTrayEvents, this));
      $(document).on('custom_kits:tray:open', $.proxy(this.openTrayEvents, this));
      $(document).on('custom_kits:tray:review:open', $.proxy(this.openReviewTab, this));
      $(document).on('custom_kits:tray:review:close', $.proxy(this.closeReviewTab, this));
      $(document).on('custom_kits:tray:scroll:open', $.proxy(this.openScrollTab, this));
      $(document).on('custom_kits:tray:scroll:close', $.proxy(this.closeScrollTab, this));

      this.nodes.$trayReviewTab.on('click', function (e) {
        e.preventDefault();
        $(document).trigger('custom_kits:tray:open');
      });

      this.nodes.$trayScrollTab.on('click', function (e) {
        e.preventDefault();
        $(window).scrollTop(0);
      });
    },

    getNodes: function () {
      this.nodes.$tray = $('.js-custom-kit-tray');
      this.nodes.$steps = $('.custom-kits-landing__steps');
      this.nodes.$trayWrapper = $('.js-custom-kit-tray-wrapper', this.nodes.$tray);
      this.nodes.$trayReviewTab = $('.js-custom-kit-tray-review');
      this.nodes.$trayScrollTab = $('.js-custom-kit-tray-scroll');
      this.nodes.$trayCarouselUL = $('.thumb-row', this.nodes.$trayWrapper);
      this.nodes.$maxErrorBanner = $('.js-max-error-banner');
      this.nodes.$maxErrorMessage = $('.js-max-error-message');
    },

    onAddToGiftSuccess: function (e, args) {
      args = args || {};
      $(document).trigger('custom_kits:tray:open', args);
    },

    onRemoveFromGiftFailure: function (e, args) {
      // This probably seems a little odd, but even if the remove fails, we still want to re-render the tray
      this.onRemoveFromGiftSuccess(e, args);
    },

    onRemoveFromGiftSuccess: function (e, args) {
      var that = this;

      that.removeMaxErrors();

      if (that.nodes.$tray.length) {
        $(document).trigger('custom_kits:get_gift_items', {
          onSuccess: function (response) {
            that.collection = response;
            if (that.collection.total_items <= 0) {
              that.closeTray();
              landingPageUrl = site.tray.landing_page_url_ck;
              $(document).trigger('custom_kits:clearCollection');

              return null;
            }
            that.renderTray({
              callback: function () {
                that.setThumbDeleteBtns();
              }
            });
          }
        });
      }
    },

    removeMaxErrors: function () {
      this.nodes.$maxErrorBanner.addClass('hidden');
      $('.js-max-error-message').addClass('hidden');
    },

    renderTray: function (args) {
      var collectionInfo = this.collection;

      if (!args || !collectionInfo) {
        return null;
      }
      collectionInfo.itemCopy = this.data.totalCopyPlural; // should always say 1/6 items, etc. (plural)
      collectionInfo = $.extend(collectionInfo, this.data);
      // collectionInfo.skus = collectionInfo.skus.concat(site.custom_kits.getEngravedSkusForGiftCollection(collectionInfo));
      var output = Mustache.render(this.mustache['___bespoke___tray___info'], collectionInfo);

      this.nodes.$trayWrapper.html(output);

      this.renderCarousel({
        collection: collectionInfo
      });

      this.setTrayCarousel();
      this.setMaxProducts();

      if (args.callback) {
        args.callback();
      }
    },

    setTray: function (e) {
      this.nodes.$trayWrapper.hide();

      if (site.client.isMobile) {
        // Set tray scroller display only on mobile.
        this.setTrayScroller();
      }

      // Set scroll top on both PC and mobile.
      this.setScrollTabScroller();
    },

    setTrayScroller: function () {
      var that = this;

      $(window)
        .off('scroll')
        .on('scroll', function () {
          if (that.collection.total_items > 0) {
            that.scrollTop = that.scrollTop || window.pageYOffset;

            var scrollAmount = window.scrollY - that.scrollTop;

            if (scrollAmount > 150 || scrollAmount < -150) {
              that.closeTray();
            }
          }
        });
    },

    resetTrayScroller: function () {
      this.scrollTop = window.pageYOffset;
    },

    setScrollTabScroller: function () {
      var that = this;

      that.nodes.$trayScrollTab.hide();
      $(window).on('scroll', function () {
        if (window.pageYOffset > 150) {
          that.openScrollTab();
        } else {
          that.closeScrollTab();
        }
      });
    },

    openTrayEvents: function (e, args) {
      var that = this;

      args = args || {};
      if (that.nodes.$tray.length) {
        $(document).trigger('custom_kits:get_gift_items', {
          onSuccess: function (response) {
            that.collection = response;
            if (that.collection && that.collection.skus && that.collection.skus.length > 0) {
              that.renderTray({
                callback: function () {
                  that.setThumbDeleteBtns();
                  // open tray
                  that.openTray({
                    params: args.params
                  });
                }
              });
            }
          }
        });
      }
    },

    closeTrayEvents: function (e, args) {
      this.closeTray(args);
      this.openReviewTab();
    },

    openTray: function (args) {
      args = args || {};
      var modArgs = $.extend(args, {
        $node: this.nodes.$trayWrapper
      });

      this.nodes.$steps.addClass('custom-kits-landing__steps--padding');
      this.openNode(modArgs);
    },

    closeTray: function (args) {
      args = args || {};
      var modArgs = $.extend(args, {
        $node: this.nodes.$trayWrapper
      });

      this.closeNode(modArgs);
      this.nodes.$steps.removeClass('custom-kits-landing__steps--padding');
      this.openReviewTab();
    },

    openReviewTab: function (e, args) {
      if (this.collection.total_items <= 0) {
        return null;
      }
      args = args || {};
      var modArgs = $.extend(args, {
        $node: this.nodes.$trayReviewTab
      });

      this.openNode(modArgs);
    },

    closeReviewTab: function (e, args) {
      args = args || {};
      var modArgs = $.extend(args, {
        $node: this.nodes.$trayReviewTab
      });

      this.closeNode(modArgs);
    },

    openScrollTab: function (e, args) {
      args = args || {};
      var modArgs = $.extend(args, {
        $node: this.nodes.$trayScrollTab
      });

      this.openNode(modArgs);
    },

    closeScrollTab: function (e, args) {
      args = args || {};
      var modArgs = $.extend(args, {
        $node: this.nodes.$trayScrollTab
      });

      this.closeNode(modArgs);
    },

    openNode: function (args) {
      args = args || {};
      if (!args && !args.$node) {
        return null;
      }
      var openParams = $.extend(this.defaults, args.params);
      var completeCallback = args.onComplete || openParams.open.onComplete;

      args.$node.slideDown(openParams.open.duration, function () {
        if (completeCallback) {
          completeCallback();
        }
        $(this).css('overflow', 'visible');
      });
    },

    closeNode: function (args) {
      args = args || {};
      if (!args && !args.$node) {
        return null;
      }
      args = args || {};
      var closeParams = args.params ? args.params : this.defaults;
      var completeCallback = args.onComplete || closeParams.close.onComplete;

      args.$node.slideUp(closeParams.close.duration, completeCallback);
    },

    setThumbDeleteBtns: function () {
      $('.js-product-delete').on('click', function () {
        if ($(this).data('collection-id')) {
          $(document).trigger('custom_kits:remove_engraved_from_gift', {
            engravedCollectionId: $(this).data('collection-id')
          });
        } else {
          var skuId = $(this).data('sku-id');
          var skuQty = $(this).data('sku-qty');

          $(document).trigger('custom_kits:remove_from_gift', {
            skuId: skuId,
            skuQty: skuQty
          });
        }
      });
    },

    loadTemplates: function () {
      var that = this;

      jQuery("script[path*='/bespoke/tray/']").each(function () {
        var template = jQuery(this);
        var path = template.attr('path');
        var modPath = path ? path.replace(/\//g, '___') : null;

        if (modPath) {
          that.mustache[modPath] = template.html();
        }
      });
    },

    renderCarousel: function (args) {
      if (site.client.isMobile || !args.collection || !args.collection.skus) {
        return null;
      }

      var collection = args.collection;
      var $thumbsContainer = $('.js-gift-thumbs', this.nodes.$trayWrapper);
      var currentLI = $('<li style="display:block;"><ul class="thumb-row clearfix"></ul></li>');
      var currentUL = (this.nodes.$trayCarouselUL = currentLI.find('ul'));
      var qtys = collection.qtys;
      var i;
      var len;
      var skus_to_render = [];
      var sku;

      // Skus with qty > 1 should be displayed once for each of their qty
      for (i = 0, len = collection.skus.length; i < len; i++) {
        sku = collection.skus[i];
        if (qtys[sku.SKU_BASE_ID] > 1) {
          for (var j = 0; j < qtys[sku.SKU_BASE_ID]; j++) {
            skus_to_render.push(sku);
          }
        } else {
          skus_to_render.push(sku);
        }
      }

      for (i = 0, len = skus_to_render.length; i < len; i++) {
        sku = skus_to_render[i];
        sku.SKU_QTY = qtys[sku.SKU_BASE_ID];
        sku.remove_btn_copy = collection.remove_btn_copy;

        // check under which key product image is stored - diff. brands use diff. keys
        // check if has sku-specific image
        if (sku.IMAGE_M) {
          sku.SMALL_IMAGE = sku.IMAGE_M[0] ? sku.IMAGE_M[0] : sku.IMAGE_M;
        } else if (sku.MEDIUM_IMAGE) {
          sku.SMALL_IMAGE = sku.MEDIUM_IMAGE[0] ? sku.MEDIUM_IMAGE[0] : sku.MEDIUM_IMAGE;
        } else if (sku.product.IMAGE_M) {
          // else use product image
          sku.SMALL_IMAGE = sku.product.IMAGE_M[0] ? sku.product.IMAGE_M[0] : sku.product.IMAGE_M;
        } else if (sku.product.IMAGE_L) {
          sku.SMALL_IMAGE = sku.product.IMAGE_L[0] ? sku.product.IMAGE_L[0] : sku.product.IMAGE_L;
        }

        var thumbHtml = Mustache.render(this.mustache['___bespoke___tray___item'], sku);

        currentUL.append(thumbHtml);
      }
      $thumbsContainer.append(currentLI);
    },

    getCollectionData: function () {
      var that = this;

      $(document).trigger('custom_kits:get_gift_items', {
        onSuccess: function (collection) {
          that.collection = collection;
          if (that.collection.skus && that.collection.skus.length > 0) {
            $(document).trigger('custom_kits:tray:open');
          }
          that.setTray();
        }
      });
    },

    getTrayData: function () {
      if (!site && !site.tray) {
        return null;
      }
      this.data = site.custom_kits.tray = site.tray ? site.tray : {};
    },

    setTrayCarousel: function () {
      this.nodes.$trayCarouselUL.slick({
        dots: false,
        infinite: false,
        speed: 300,
        slidesToScroll: 1,
        slidesToShow: 5,
        lazyLoad: 'ondemand',
        responsive: [
          {
            breakpoint: 1800,
            settings: {
              slidesToShow: 4
            }
          },
          {
            breakpoint: 1420,
            settings: {
              slidesToShow: 3
            }
          },
          {
            breakpoint: 1125,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 1
            }
          }
        ]
      });
    },

    setMaxProducts: function () {
      var maxProducts;

      if (!_.isUndefined(site.templates.custom_kits_product_tray)) {
        maxProducts = site.templates.custom_kits_product_tray.data.gift_items_max_allowed;
        $('.gift-info__total > span:first-child').append('/' + maxProducts);
      }
    },

    setFixedTray: function (scrolled) {
      var maxWidth = 1024;
      var minWidth = 737;
      var $header = $('.page-header');
      var $utilNav = $('.page-header__utils');
      var $offersContainer = $('.gnav-offers-block');
      var offersHeight = $offersContainer.outerHeight(false);
      var navHeight = $utilNav.outerHeight(false);
      var headerHeight = $header.outerHeight(false);
      var trayHeight = this.nodes.$tray.outerHeight(false);

      if ($(window).width() < maxWidth && $(window).width() > minWidth) {
        if (scrolled >= offersHeight) {
          this.nodes.$tray.css({ top: navHeight });
          this.nodes.$maxErrorBanner.css({ top: navHeight + trayHeight });
        } else {
          this.nodes.$tray.css({ top: '' });
          this.nodes.$maxErrorBanner.css({ top: headerHeight + trayHeight });
        }
      } else {
        this.nodes.$tray.css({ top: '' });
        this.nodes.$maxErrorBanner.css({ top: '' });
      }
    }
  };
})(jQuery);
